import React, { useEffect, useRef } from 'react'
import hoverEffect from 'hover-effect'
import Img1 from '../assets/test/businesscard-02.jpg'
import Img2 from '../assets/test/businesscard-01.jpg'
import displacement from '../assets/images/map-9.jpg'

const TestPage = () => {
  const img = useRef(null)

  useEffect(() => {
    const effect = new hoverEffect({
        parent: img.current,
        intensity: 0.05,
        imagesRatio: 797 / 1280,
        image1: Img1,
        image2: Img2,
        displacementImage: displacement
    })
  }, [])
  return(
    <div id='test'>
      <div ref={img} className='test-container selectDisable' data-sal='fade' data-sal-delay='200' data-sal-duration='700' data-sal-easing='ease'></div>
    </div>
  )
}


export default TestPage
